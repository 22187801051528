<!--
 * @Author: gaojingran
 * @Date: 2021-04-07 10:58:04
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-13 10:15:21
 * @Description: 首页
-->

<style lang="less" scoped>
@import './index.less';
</style>

<template>
  <div class="home-page pl-4 pr-4">
    <!-- 下单方 -->
    <div class="pt-4" v-if="$role('home_customerView')">
      <div style="display: flex; justify-content: space-between">
        <!-- 日期筛选 -->
        <NeoBreadcrumb :options="dateOptions" v-model="dataValue" />
        <span class="view-more" @click="$router.push({ path: '/report' })"
          >{{ $t('home.more') }} <a-icon type="right"
        /></span>
      </div>

      <!-- 统计 -->
      <div class="statistics-contianer">
        <StatisticsCard
          icon="c_order"
          subIcon="c_order_small"
          :title="$t('home.cumulative_order')"
          :unit="$t('home.cumulative_order_unit')"
          :subTitle="$t('home.cumulative_order_subtitle')"
          :number="numeral(totalOrders < 0 ? 0 : totalOrders).format('0.[]')"
        />
        <StatisticsCard
          icon="c_saving"
          subIcon="c_saving_small"
          :title="$t('home.order_volume')"
          :unit="agencyInfo.currency"
          :subTitle="$t('home.order_volume_subtitle')"
          :number="numeral(orderMoneyCount < 0 ? 0 : orderMoneyCount).format('0.[00]')"
          v-if="noOrderer"
        />
        <StatisticsCard
          icon="s_time"
          subIcon="s_time_small"
          :title="$t('home.translated_wordcount')"
          :subTitle="$t('home.translated_wordcount_subtitle')"
          :number="translationWordCountComputed"
        />

        <StatisticsCard
          icon="c_corpora"
          subIcon="c_corpora_small"
          :title="$t('home.cumulative_corpora')"
          :unit="$t('home.cumulative_corpora_unit')"
          :subTitle="$t('home.cumulative_corpora_subtitle')"
          :number="numeral(totalCorpus < 0 ? 0 : totalCorpus).format('0.[]')"
        />
      </div>
      <!-- 操作 -->
      <div class="handle-container">
        <!-- 立即下单 -->
        <div class="handle-item">
          <div class="icon-circle">
            <span class="number">01</span>
            <NeoIcon class="icon" name="h_order" />
            <NeoIcon class="icon active" name="h_active" />
          </div>
          <p class="item-title" :title="$t('home.btn_order_holder')">{{ $t('home.btn_order_holder') }}</p>
          <a-button class="neo-btn-brand" @click="$router.push({ path: $g.route.order_newOrder })">
            {{ $t('home.btn_order') }}
          </a-button>
        </div>
        <!-- 审核报价 -->
        <div class="handle-item" v-if="noOrderer">
          <div class="icon-circle">
            <span class="number">02</span>
            <NeoIcon class="icon" name="h_quote" />
            <NeoIcon class="icon active" name="h_active" />
          </div>
          <p class="item-title" :title="$t('home.btn_quote_holder')">{{ $t('home.btn_quote_holder') }}</p>
          <a-button
            class="neo-btn-brand"
            @click="$router.push({ path: $g.route.project_list, query: { tabVal: 2 } })"
            >{{ $t('home.btn_quote') }}</a-button
          >
        </div>
        <!-- 验收终稿 -->
        <div class="handle-item">
          <div class="icon-circle">
            <span class="number">03</span>
            <NeoIcon class="icon" name="h_accept" />
            <NeoIcon class="icon active" name="h_active" />
          </div>
          <p class="item-title" :title="$t('home.btn_accept_holder')">{{ $t('home.btn_accept_holder') }}</p>
          <a-button
            class="neo-btn-brand"
            @click="$router.push({ path: $g.route.project_list, query: { tabVal: '5' } })"
            >{{ $t('home.btn_accept') }}</a-button
          >
        </div>
        <!-- 确认结算 -->
        <div class="handle-item" v-if="noOrderer">
          <div class="icon-circle">
            <span class="number">04</span>
            <NeoIcon class="icon" name="h_account" />
            <NeoIcon class="icon active" name="h_active" />
          </div>
          <p class="item-title" :title="$t('home.btn_account_holder')">{{ $t('home.btn_account_holder') }}</p>
          <a-button class="neo-btn-brand" @click="$router.push({ path: '/payment', query: { tabVal: '1,3' } })">{{
            $t('home.btn_account')
          }}</a-button>
        </div>
      </div>
    </div>
    <!-- 供应商 -->
    <div class="provider-view pt-4" v-if="$role('home_providerView')">
      <!-- 创建报价 -->
      <div class="provider-view-item">
        <div class="desc">
          <p class="number">01</p>
          <p class="title ellipsis">{{ $t('home.btn_create_quotation_holder') }}</p>
          <a-button
            class="neo-btn-brand"
            @click="$router.push({ path: $g.route.project_list, query: { tabVal: 1 } })"
            >{{ $t('home.btn_create_quotation') }}</a-button
          >
        </div>
        <div class="icon-circle">
          <NeoIcon class="icon" name="h_accept" />
          <NeoIcon class="icon active" name="h_active" />
        </div>
      </div>
      <!-- 确认结算 -->
      <div class="provider-view-item">
        <div class="desc">
          <p class="number">02</p>
          <p class="title ellipsis">{{ $t('home.settlement_holder') }}</p>
          <a-button class="neo-btn-brand" @click="$router.push({ path: '/payment', query: { tabVal: '2' } })">{{
            $t('home.btn_account')
          }}</a-button>
        </div>
        <div class="icon-circle">
          <NeoIcon class="icon" name="h_account" />
          <NeoIcon class="icon active" name="h_active" />
        </div>
      </div>
    </div>
    <!-- 待办事项 -->
    <div class="todo-view mt-4">
      <div class="todo-view-header mb-2">
        <span class="title">{{ $t('home.todo') }}</span>
        <span class="more" @click="$router.push({ path: '/project/schedule' })"
          >{{ $t('home.all') }} <a-icon type="right"
        /></span>
      </div>
      <a-table
        class="no-border"
        size="middle"
        rowKey="id"
        :data-source="schedule.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
        :pagination="false"
        :bordered="false"
      >
        <a-table-column key="index" align="center" :width="80" :title="$t('home.table_index')">
          <template slot-scope="text, record, index">
            {{ index + 1 }}
          </template>
        </a-table-column>

        <a-table-column key="orderName" :ellipsis="true" :title="$t('home.table_order')">
          <template slot-scope="text">
            <NeoIcon v-if="text.tag === 1" class="error" name="fire" :size="18" />
            <NeoIcon v-if="text.tag === 0" class="warning" name="fire" :size="18" />
            <span class="pl-2" style="cursor: pointer" @click="handleJump(text)">{{
              handledOrderObj(text).name || empty
            }}</span>
          </template>
        </a-table-column>

        <a-table-column key="status" align="center" :width="160" :title="$t('home.table_status')">
          <template slot-scope="text">
            <NeoTag :type="localeDict.warningTagType[text.tag] || 'primary'">{{
              localeDict.warningTagDict[text.tag] || localeDict.warningTagDict[null]
            }}</NeoTag>
          </template>
        </a-table-column>

        <!-- 企业实体-供应商可以查看 -->
        <a-table-column key="cusAgencyName" v-if="$g.isCust" align="left" :width="300" :title="$t('schedule.entity')">
          <template slot-scope="row">
            <span>{{ row.supTenantName || empty }}</span>
          </template>
        </a-table-column>

        <a-table-column key="updateData" :ellipsis="true" width="20%" :title="$t('home.table_update_time')">
          <template slot-scope="text">
            <span>{{ handledOrderObj(text).time || empty }}</span>
          </template>
        </a-table-column>
      </a-table>
      <!-- <a-pagination
        class="mt-3 fr"
        show-size-changer
        :total="totalNum"
        :page-size="pageSize"
        @change="onChangePage"
        @showSizeChange="onShowSizeChange"
      /> -->
    </div>
  </div>
</template>

<script>
import numeral from 'numeral'
import withSchedule from '@/mixins/withSchedule'
import NeoBreadcrumb from '@/components/common/NeoBreadcrumb'
import NeoTag from '@/components/common/NeoTag'
import StatisticsCard from './components/StatisticsCard'
import localeDict from '@/configs/localDict'
import moment from 'moment'
export default {
  name: 'StoneHome',
  mixins: [withSchedule],
  components: { NeoBreadcrumb, StatisticsCard, NeoTag },
  data() {
    return {
      dateOptions: [
        {
          label: this.$t('home.all'),
          value: -1,
        },
        {
          label: this.$t('home.one_month'),
          value: 1,
        },
        {
          label: this.$t('home.six_month'),
          value: 6,
        },
        {
          label: this.$t('home.twelve_month'),
          value: 12,
        },
      ],
      dataValue: -1,
      schedule: [],
      totalOrders: '',
      totalCorpus: '',
      savingCost: '',
      savingTime: '',
      orderMoneyCount: 0,
      translationWordCount: 0,
      numeral,
      localeDict,
      empty: '---',
      moment,
      totalNum: 0,
      currentPage: 1,
      pageSize: 7,
      agencyInfo: { currency: '' },
    }
  },
  mounted() {
    this.fetchSummaryInfo()
    this.getSchedule()
    if (this.$g.isCust) {
      this.getAgencyInfo()
    }
    this.$bus.$on('refresh_home_page', () => {
      console.warn('refresh_home_page__')
      this.getAgencyInfo()
    })
  },
  computed: {
    noOrderer() {
      return !this.$g.isOrderer
    },
    translationWordCountComputed() {
      const num = this.translationWordCount
      const data = num < 0 ? 0 : num
      let result = 0
      if (data >= 10000) {
        result = numeral(data / 10000).format('0.[00]') + 'w'
      } else {
        result = data
      }
      return result
    },
  },
  watch: {
    dataValue(val) {
      this.screeningTime(val)
    },
  },
  methods: {
    // 获取基本企业基本信息
    async getAgencyInfo() {
      try {
        const data = await this.$http({
          key: 'getCusAgencyInfo',
          params: { id: this.$store.state.app.userInfo.agencyId },
        })
        this.agencyInfo = data
        this.agencyInfo.currency = this.getCurrency(this.agencyInfo.currency)
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    getCurrency(val) {
      return this.$store.getters['app/getDictLabel']('CURRENCY', val).split(';')[0]
    },
    onChangePage(page) {
      this.currentPage = page
    },
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize
    },
    fetchSummaryInfo(nearlyMonths = null) {
      this.getData('totalOrders', nearlyMonths)
      this.getData('totalCorpus', nearlyMonths)
      this.getData('orderMoneyCount', nearlyMonths)
      this.getData('translationWordCount', nearlyMonths)
    },
    screeningTime(val) {
      if (val === -1) {
        this.fetchSummaryInfo()
      } else {
        this.fetchSummaryInfo(val)
      }
    },
    async getData(name, nearlyMonths) {
      try {
        const data = await this.$http(name, nearlyMonths ? { nearlyMonths } : null)
        this[name] = data
      } catch (error) {
        this.$httpNotify(error)
      }
    },
    async getSchedule(todoTag = null) {
      try {
        const data = await this.$http('stoneTodoPage', todoTag ? { todoTag } : null)
        this.schedule = data
        this.totalNum = this.schedule.length
      } catch (error) {
        this.$httpNotify(error)
      }
    },
  },
}
</script>
